import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import appData from './appData'
import settings from './settings'
import library from './library'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		appData,
		library,
	},
	state: {
		instances: [],
		instance: null,
		hives: [],
		loading: false,
		cats: [],
		pageButtons: [],
		uploadDialog: false,
		folders: [],
		files: {},
		currentFolder: '/',
		theApp: {},
		step: '',
		fileStructure: []
	},
	mutations: {
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		LOAD: (state, bool = !state.loading) => state.loading = bool,
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons
		},
		CURRENT_STEP(state, data) {
			state.step = data
		},

		SET_APP(state, data) {
			state.theApp = data
		},
		cancelUpload: (state) => {
			state.uploadDialog = false
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true
			state.folders = folders
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},
		SET_INSTANCES: (state, data) => {
			state.instances = data
		}

	},
	getters: {
		hasReservationAccess(state) {
			return state?.instance?.package?.options?.access?.reservation || false;
		},
	},
	actions: {



	},
})
