<template>
    <div style="height: 100%">
        <LeftNavBar
            :title="selectedAppInitial"
            :hasChild="false"
            :items="sidebarItems"
            @opened="filterType"
            :selectedValue="filter"
        />
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
    components: { LeftNavBar },
    name: "menu-left",
    data() {
        return {
            openID: "",
        };
    },
    computed: {
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "WLPB"
            );
        },
        sidebarItems() {
            return [
                {
                    label: "All Projects",
                    value: "",
                    iconUrl: require("@/assets/sideIcons/all.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Microsites",
                    value: "microsite",
                    iconUrl: require("@/assets/sideIcons/microsite.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Landing Pages",
                    value: "landing",
                    iconUrl: require("@/assets/sideIcons/landing.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
				{
                    label: "Static Projects",
                    value: "static",
                    iconUrl: require("@/assets/sideIcons/static.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Published",
                    value: "published",
                    iconUrl: require("@/assets/sideIcons/published.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
            ];
        },
        forms() {
            return Object.values(this.$store.state.forms.allForms);
        },
        lots() {
            return this.$store.state.forms.lots;
        },
        folders() {
            return this.$store.getters.folders;
        },
        files() {
            return this.$store.getters.allFiles;
        },
        filter() {
            return this.$store.state.appData.filter;
        },
    },
    methods: {
        filterType(data) {
            console.log("DATAA", data);
            let path = this.$route.path;

            if (path !== "/") this.$router.push("/");
            this.$store.commit("SET_PROP", {
                where: ["filter"],
                what: data.value,
            });
        },
        handleClick(e) {
            this.$store.commit("changeFolder", e.key);
        },
    },
};
</script>
