<template>
    <SubBarGlobal :currentCrumb="currentCrumb">
        <PageButtons />
    </SubBarGlobal>
</template>
<script>
import SubBarGlobal from "bh-mod/components/layout/SubBarGlobal";
import PageButtons from "bh-mod/components/layout/SubBarGlobal/PageButtons";

export default {
    components: { SubBarGlobal, PageButtons },

    computed: {
        currentCrumb() {
            let lookup = {
                "": "All Projects",
                published: "Published Projects",
                microsite: "Microsites",
                landing: "Landing Pages",
				static: "Static Projects",
            };
            if (this.$route.meta.crumbName) return false;
            if (this.$route.path !== "/") return "Unknown Page";
            let filter = this.$store.state.appData.filter;
            return lookup[filter];
        },
    },
};
</script>

<style lang="scss">
</style>
